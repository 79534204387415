@media screen and (max-width: 1023px) {
  .desktopOnly {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .mobileOnly {
    display: none;
  }
}
