@font-face {
  font-family: "PlusJakartaSans";
  font-display: swap;
  src: url("/fonts/PlusJakartaSans-VariableFont_wght.ttf") format("truetype");
}

html,
body {
  scroll-behavior: smooth;
  background: #f3f4f7;
  font-family: "PlusJakartaSans";
}

main {
  min-height: 100vh;
  min-height: 100dvh;
}
